import Dashboard from './pages/dashboard/Index.svelte'

import Questions from './pages/questions/Index.svelte'
import NewQuestions from './pages/questions/New.svelte'
import EditQuestion from './pages/questions/Edit.svelte'

import Pictures from './pages/pictures/Index.svelte'
import NewPictures from './pages/pictures/New.svelte'
import EditPicture from './pages/pictures/Edit.svelte'

import Music from './pages/music/Index.svelte'
import NewMusic from './pages/music/New.svelte'
import EditTrack from './pages/music/Edit.svelte'

import Venues from './pages/venues/Index.svelte'
import NewVenues from './pages/venues/New.svelte'

import Events from './pages/events/Index.svelte'
import NewEvent from './pages/events/New.svelte'

import Quizzes from './pages/quizzes/Index.svelte'
import NewQuiz from './pages/quizzes/New.svelte'

import LiveQuiz from './live/quiz/Index.svelte'
import LiveBonus from './live/bonus/Index.svelte'
import LiveSubmissions from './live/submissions/Index.svelte'
import LiveSubmissionsMark from './live/submissions/Mark.svelte'
import LiveTeams from './live/teams/Index.svelte'
import LiveTeamsEdit from './live/teams/Edit.svelte'

export const routes = [
  {
    path: '',
    type: 'page',
    component: Dashboard,
    title: 'Dashboard'
  },
  {
    path: 'questions',
    type: 'page',
    component: Questions,
    title: 'Questions',
    pages: [
      {
        path: 'new',
        type: 'page',
        component: NewQuestions,
        title: 'New Questions'
      },
      {
        path: 'edit/:id',
        type: 'page',
        component: EditQuestion,
        title: 'Edit Question'
      }
    ]
  },
  {
    path: 'pictures',
    type: 'page',
    component: Pictures,
    title: 'Pictures',
    pages: [
      {
        path: 'new',
        type: 'page',
        component: NewPictures,
        title: 'Upload Pictures'
      },
      {
        path: 'edit/:id',
        type: 'page',
        component: EditPicture,
        title: 'Edit Picture'
      }
    ]
  },
  {
    path: 'music',
    type: 'page',
    component: Music,
    title: 'Music',
    pages: [
      {
        path: 'new',
        type: 'page',
        component: NewMusic,
        title: 'New Music'
      },
      {
        path: 'edit/:id',
        type: 'page',
        component: EditTrack,
        title: 'Edit Track'
      }
    ]
  },
  {
    path: 'venues',
    type: 'page',
    component: Venues,
    title: 'Venues',
    pages: [
      {
        path: 'new',
        type: 'page',
        component: NewVenues,
        title: 'New Venues'
      }
    ]
  },
  {
    path: 'quizzes',
    type: 'page',
    component: Quizzes,
    title: 'Quizzes',
    pages: [
      {
        path: 'new',
        type: 'page',
        component: NewQuiz,
        title: 'New Quiz'
      }
    ]
  },
  {
    path: 'events',
    type: 'page',
    component: Events,
    title: 'Events',
    pages: [
      {
        path: 'new',
        type: 'page',
        component: NewEvent,
        title: 'New Event'
      }
    ]
  },
  {
    path: 'live',
    type: 'path',
    pages: [
      {
        path: 'quiz',
        type: 'live',
        component: LiveQuiz,
        title: 'Live Quiz'
      },
      {
        path: 'bonus',
        type: 'live',
        component: LiveBonus,
        title: 'Live Bonus'
      },
      {
        path: 'submissions',
        type: 'live',
        component: LiveSubmissions,
        title: 'Live Submissions',
        pages: [
          {
            path: 'mark/:team/:index',
            type: 'live',
            component: LiveSubmissionsMark,
            title: 'Mark Submission'
          },
        ]
      },
      {
        path: 'teams',
        type: 'live',
        component: LiveTeams,
        title: 'Live Teams',
        pages: [
          {
            path: 'edit/:team',
            type: 'live',
            component: LiveTeamsEdit,
            title: 'Edit Team'
          }
        ]
      }
    ]
  }
]