<script>
import { Collection, Doc } from 'sveltefire'
import Header from '../../components/Header.svelte'

export let user, params, eventData, eventRef

let submissionData = []
let marked

function setData({ detail: { data } }) {
  let subs = []
  data.forEach((team) => {
    if (team.id === 'marked') return
    team.sections.forEach((section, index) => {
      if (eventData.sections[index].type == 'quiz' && section.submitted) {
        section.index = index
        subs.push(section)
      }
    })
  })
  submissionData = subs
}

function setMarked({ detail: { data } }) {
  if (data) {
    marked = data
  } else {
    marked = { 0: {}, 1: {}, 2: {}, 3: {} }
  }
}
</script>

{#if eventData}
  <Header title={`Submissions (${eventRef.id})`} widgets={false} />
{/if}

{#if submissionData.length && marked}
  <div class="flex flex-col mt-8">
    <div class="inline-block min-w-full py-4 align-middle sm:px-6">
      <table class="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase">
              Team
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase">
              Round
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase">
              State
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase">
              Action
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          {#each submissionData as submission}
            <tr>
              <td class="px-6 py-4 text-sm leading-5 text-gray-500">
                {submission.team.name}
              </td>
              <td class="px-6 py-4 text-sm font-medium leading-5 text-gray-500">
                {eventData.sections[submission.index].name}
              </td>
              <td class="px-6 py-4 text-sm font-medium leading-5">
                {#if marked[submission.index][submission.team.id] || submission.marked}<span
                    class="text-green-600">Marked</span
                  >{:else}<span class="text-gray-500">Unmarked</span>{/if}
              </td>
              <td class="px-6 py-4 text-sm font-medium leading-5 text-gray-500">
                {#if !marked[submission.index][submission.team.id] && !submission.marked}
                  <a
                    href={`/live/submissions/mark/${submission.team.id}/${submission.index}`}
                    class="text-purple-600 cursor-pointer hover:text-purple-900">
                    Mark
                  </a>
                {:else}
                  <a
                    href={`/live/submissions/mark/${submission.team.id}/${submission.index}`}
                    class="text-gray-400 font-light cursor-pointer hover:text-gray-500">
                    Edit
                  </a>
                {/if}
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    </div>
  </div>
{/if}
{#if eventData}
  <Collection
    path={`org/${user.org}/events/${eventRef.id}/submissions`}
    on:data={setData} />
  <Doc
    path={`org/${user.org}/events/${eventRef.id}/submissions/marked`}
    on:data={setMarked} />
{/if}
