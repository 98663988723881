<script>
import { Collection } from 'sveltefire'
import Header from '../../components/Header.svelte'

export let user, params, eventData, eventRef

let submissionData = []

function setSubmissionData({ detail: { data } }) {
  let subs = []
  data.forEach((team) => {
    team.sections.forEach((section, index) => {
      if (eventData.sections[index].type == 'game' && section.submitted) {
        subs.push({
          team: section.team,
          submission: section,
        })
      }
    })
  })
  submissionData = subs
}
</script>

{#if eventData}
  <Header title={`Bonus (${eventRef.id})`} widgets={false} />

  <img
    class="min-w-full mb-16 sm:px-6"
    src={eventData.sections[0].rounds[0].parts[0].url}
    alt="Bonus" />
{/if}

{#if submissionData.length}
  <div class="flex flex-col mt-8">
    <div class="inline-block min-w-full py-4 align-middle sm:px-6">
      <table class="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase ">
              Team
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase ">
              Caption
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          {#each submissionData as submission}
            <tr>
              <td class="px-6 py-4 text-sm leading-5 text-gray-500">
                {submission.team.name}
              </td>
              <td class="px-6 py-4 text-sm font-medium leading-5 text-gray-900">
                {submission.submission.answers[1]}
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    </div>
  </div>
{/if}

{#if eventData}
  <Collection
    path={`org/${user.org}/events/${eventRef.id}/submissions`}
    on:data={setSubmissionData} />
{/if}
