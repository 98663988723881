export const templates = [
  {
    name: 'Default',
    sections: [
      {
        name: 'Caption Round',
        description:
          'Players are given a picture and each team submits a caption. Best caption wins.',
        type: 'game',
        of: 'captions',
        rounds: [
          {
            type: 'pictures',
            of: 'picture',
            parts: [],
            optional: false,
            named: false,
            prompt: false,
            answerable: true,
            num: 1,
          },
        ],
      },
      {
        name: 'First Half',
        description:
          'The first half of the quiz, three rounds of five questions.',
        type: 'quiz',
        of: 'questions',
        rounds: [
          {
            type: 'questions',
            of: 'question',
            parts: [],
            optional: false,
            named: true,
            name: '',
            prompt: false,
            answerable: true,
            num: 5,
          },
          {
            type: 'questions',
            of: 'question',
            parts: [],
            optional: false,
            named: true,
            name: '',
            prompt: false,
            answerable: true,
            num: 5,
          },
          {
            type: 'questions',
            of: 'question',
            parts: [],
            optional: false,
            named: true,
            name: 'Free Drink Question',
            prompt: false,
            answerable: false,
            num: 1,
          },
          {
            type: 'questions',
            of: 'question',
            parts: [],
            optional: false,
            named: true,
            name: '',
            prompt: false,
            answerable: true,
            num: 5,
          },
        ],
      },
      {
        name: 'Picture Round',
        description: 'Ten pictures released at half time.',
        type: 'quiz',
        of: 'pictures',
        rounds: [
          {
            type: 'pictures',
            of: 'picture',
            parts: [],
            optional: false,
            named: false,
            prompt: true,
            answerable: true,
            num: 10,
          },
        ],
      },
      {
        name: 'Second Half',
        description: 'Two rounds of questions separated by a music round.',
        type: 'quiz',
        of: 'questions',
        rounds: [
          {
            type: 'questions',
            of: 'question',
            parts: [],
            optional: false,
            named: true,
            name: '',
            prompt: false,
            answerable: true,
            num: 5,
          },
          {
            type: 'music',
            of: 'song',
            parts: [],
            optional: false,
            named: true,
            name: 'Music Round',
            prompt: false,
            answerable: true,
            num: 5,
          },
          {
            type: 'text',
            of: 'music_link',
            parts: [],
            value: '',
            optional: true,
            named: false,
            prompt: false,
            answerable: true,
            num: 1,
          },
          {
            type: 'questions',
            of: 'question',
            parts: [],
            optional: false,
            named: true,
            name: '',
            prompt: false,
            answerable: true,
            num: 5,
          },
          {
            type: 'questions',
            of: 'question',
            parts: [],
            optional: false,
            named: true,
            name: 'Tie Breaker',
            prompt: false,
            answerable: false,
            num: 1,
          },
        ],
      },
    ],
  },
  {
    name: 'Online 1',
    sections: [
      {
        name: 'First Half',
        description: 'The first half of the quiz.',
        type: 'quiz',
        of: 'questions',
        rounds: [
          {
            type: 'questions',
            of: 'question',
            parts: [],
            optional: false,
            named: true,
            name: '',
            prompt: false,
            answerable: true,
            num: 5,
          },
          {
            type: 'text',
            of: 'picture_1',
            parts: [],
            value: '',
            optional: false,
            named: false,
            prompt: false,
            answerable: true,
            num: 1,
          },
          {
            type: 'questions',
            of: 'question',
            parts: [],
            optional: false,
            named: true,
            name: 'Bonus Question',
            prompt: false,
            answerable: false,
            num: 1,
          },
          {
            type: 'questions',
            of: 'question',
            parts: [],
            optional: false,
            named: true,
            name: '',
            prompt: false,
            answerable: true,
            num: 5,
          },
          {
            type: 'text',
            of: 'picture_2',
            parts: [],
            value: '',
            optional: false,
            named: false,
            prompt: false,
            answerable: true,
            num: 1,
          },
        ],
      },
      {
        name: 'Music Round',
        description: 'Music round for half time',
        type: 'quiz',
        of: 'questions',
        rounds: [
          {
            type: 'music',
            of: 'song',
            parts: [],
            optional: false,
            named: true,
            name: 'Music Round',
            prompt: false,
            answerable: true,
            num: 5,
          },
          {
            type: 'text',
            of: 'music_link',
            parts: [],
            value: '',
            optional: true,
            named: false,
            prompt: false,
            answerable: true,
            num: 1,
          },
        ],
      },
      {
        name: 'Second Half',
        description: 'Second half of the quiz.',
        type: 'quiz',
        of: 'questions',
        rounds: [
          {
            type: 'text',
            of: 'picture_3',
            parts: [],
            value: '',
            optional: false,
            named: false,
            prompt: false,
            answerable: true,
            num: 1,
          },
          {
            type: 'questions',
            of: 'question',
            parts: [],
            optional: false,
            named: true,
            name: '',
            prompt: false,
            answerable: true,
            num: 5,
          },
          {
            type: 'text',
            of: 'picture_4',
            parts: [],
            value: '',
            optional: false,
            named: false,
            prompt: false,
            answerable: true,
            num: 1,
          },
          {
            type: 'questions',
            of: 'question',
            parts: [],
            optional: false,
            named: true,
            name: 'Bonus Question',
            prompt: false,
            answerable: false,
            num: 1,
          },
          {
            type: 'questions',
            of: 'question',
            parts: [],
            optional: false,
            named: true,
            name: 'Genius Round',
            prompt: false,
            answerable: true,
            num: 5,
          },
        ],
      },
    ],
  },
  {
    name: 'Experimental',
    sections: [
      {
        name: 'Picture Round',
        description: 'Three pictures',
        type: 'quiz',
        of: 'pictures',
        rounds: [
          {
            type: 'pictures',
            of: 'picture',
            parts: [],
            optional: false,
            named: false,
            prompt: true,
            answerable: true,
            num: 3,
          },
        ],
      },
      {
        name: 'Question Round',
        description: 'Twenty questions',
        type: 'quiz',
        of: 'questions',
        rounds: [
          {
            type: 'questions',
            of: 'question',
            parts: [],
            optional: false,
            named: true,
            name: '',
            prompt: false,
            answerable: true,
            num: 20,
          },
        ],
      },
    ],
  },
  {
    name: 'Default with Music in First Round of Second Half',
    sections: [
      {
        name: 'Caption Round',
        description:
          'Players are given a picture and each team submits a caption. Best caption wins.',
        type: 'game',
        of: 'captions',
        rounds: [
          {
            type: 'pictures',
            of: 'picture',
            parts: [],
            optional: false,
            named: false,
            prompt: false,
            answerable: true,
            num: 1,
          },
        ],
      },
      {
        name: 'First Half',
        description:
          'The first half of the quiz, three rounds of five questions.',
        type: 'quiz',
        of: 'questions',
        rounds: [
          {
            type: 'questions',
            of: 'question',
            parts: [],
            optional: false,
            named: true,
            name: '',
            prompt: false,
            answerable: true,
            num: 5,
          },
          {
            type: 'questions',
            of: 'question',
            parts: [],
            optional: false,
            named: true,
            name: '',
            prompt: false,
            answerable: true,
            num: 5,
          },
          {
            type: 'questions',
            of: 'question',
            parts: [],
            optional: false,
            named: true,
            name: 'Free Drink Question',
            prompt: false,
            answerable: false,
            num: 1,
          },
          {
            type: 'questions',
            of: 'question',
            parts: [],
            optional: false,
            named: true,
            name: '',
            prompt: false,
            answerable: true,
            num: 5,
          },
        ],
      },
      {
        name: 'Picture Round',
        description: 'Ten pictures released at half time.',
        type: 'quiz',
        of: 'pictures',
        rounds: [
          {
            type: 'pictures',
            of: 'picture',
            parts: [],
            optional: false,
            named: false,
            prompt: true,
            answerable: true,
            num: 10,
          },
        ],
      },
      {
        name: 'Second Half',
        description: 'Music round followed by two rounds of questions',
        type: 'quiz',
        of: 'questions',
        rounds: [
          {
            type: 'music',
            of: 'song',
            parts: [],
            optional: false,
            named: true,
            name: 'Music Round',
            prompt: false,
            answerable: true,
            num: 5,
          },
          {
            type: 'text',
            of: 'music_link',
            parts: [],
            value: '',
            optional: true,
            named: false,
            prompt: false,
            answerable: true,
            num: 1,
          },
          {
            type: 'questions',
            of: 'question',
            parts: [],
            optional: false,
            named: true,
            name: '',
            prompt: false,
            answerable: true,
            num: 5,
          },
          {
            type: 'questions',
            of: 'question',
            parts: [],
            optional: false,
            named: true,
            name: '',
            prompt: false,
            answerable: true,
            num: 5,
          },
          {
            type: 'questions',
            of: 'question',
            parts: [],
            optional: false,
            named: true,
            name: 'Tie Breaker',
            prompt: false,
            answerable: false,
            num: 1,
          },
        ],
      },
    ],
  },
]
