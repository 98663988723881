<script>
import { fade, fly } from 'svelte/transition'

export let auth

import { live } from './stores.js'
import Logo from './components/Logo.svelte'
import NavLink from './components/NavLink.svelte'

const main = [
  'dashboard',
  'quizzes',
  'questions',
  'pictures',
  'music',
  'venues',
  'events',
]

const extra = ['quiz', 'submissions', 'teams']

let showMenu = false

function toggleMenu() {
  showMenu = !showMenu
}
function signOut() {
  auth.signOut()
}
</script>

<div class="flex h-screen overflow-hidden bg-white">
  <!-- Off-canvas menu for mobile -->
  {#if showMenu}
    <div class="z-50 md:hidden">
      <div class="fixed inset-0 flex">
        <div transition:fade={{ duration: 200 }} class="fixed inset-0">
          <div class="absolute inset-0 bg-gray-600 opacity-75" />
        </div>
        <div
          in:fly={{ duration: 300, opacity: 1 }}
          out:fly={{ duration: 300, opacity: 0 }}
          class="relative flex flex-col flex-1 w-full max-w-xs bg-cool-gray-900">
          <div class="absolute top-0 right-0 p-1 -mr-14">
            <button
              on:click={toggleMenu}
              class="flex items-center justify-center w-12 h-12 rounded-full focus:outline-none focus:bg-gray-600"
              aria-label="Close sidebar">
              <svg
                class="w-6 h-6 text-white"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div class="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto">
            <div class="w-full px-4">
              <h1
                class="mt-2 mb-8 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl sm:leading-10">
                <Logo />
              </h1>
            </div>
            <nav class="flex-1 px-2 mt-5">
              <div>
                <h3
                  class="px-3 text-xs font-semibold leading-4 tracking-wider text-gray-500 uppercase">
                  QUIZDOG
                </h3>
                {#each main as name}
                  <NavLink on:close={toggleMenu} name={name} />
                {/each}
              </div>
              {#if $live}
                <div class="mt-8">
                  <h3
                    class="px-3 text-xs font-semibold leading-4 tracking-wider text-gray-500 uppercase">
                    {$live}
                  </h3>
                  {#each extra as name}
                    <NavLink on:close={toggleMenu} name={name} live={$live} />
                  {/each}
                </div>
              {/if}
            </nav>
          </div>
          <div
            on:click={signOut}
            class="flex flex-shrink-0 p-4 border-t border-gray-200 cursor-pointer hover:bg-gray-100">
            Sign out
          </div>
        </div>
        <div class="flex-shrink-0 w-14" />
      </div>
    </div>
  {/if}
  <!-- Static sidebar for desktop -->
  <div class="hidden md:flex md:flex-shrink-0">
    <div class="flex flex-col w-64">
      <div
        class="flex flex-col flex-1 h-0 border-r border-cool-gray-700 bg-cool-gray-900">
        <div class="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto">
          <div class="w-full px-4">
            <h1
              class="mt-2 mb-8 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl sm:leading-10">
              <Logo />
            </h1>
          </div>
          <nav class="flex-1 px-2 mt-5">
            <div>
              <h3
                class="px-3 text-xs font-semibold leading-4 tracking-wider text-gray-500 uppercase">
                QUIZDOG
              </h3>
              {#each main as name}
                <NavLink name={name} />
              {/each}
            </div>
            {#if $live}
              <div class="mt-8">
                <h3
                  class="px-3 text-xs font-semibold leading-4 tracking-wider text-gray-500 uppercase">
                  {$live}
                </h3>
                {#each extra as name}
                  <NavLink name={name} live={$live} />
                {/each}
              </div>
            {/if}
          </nav>
        </div>
        <div
          on:click={signOut}
          class="flex flex-shrink-0 p-4 border-t border-gray-200 cursor-pointer hover:bg-gray-100">
          Sign out
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col flex-1 w-0 overflow-hidden">
    <div class="pt-1 pl-1 md:hidden sm:pl-3 sm:pt-3">
      <button
        on:click={toggleMenu}
        class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md
          text-gray-500 hover:text-gray-900 focus:outline-none focus:bg-gray-200 transition
          ease-in-out duration-150"
        aria-label="Open sidebar">
        <svg
          class="w-6 h-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16M4 18h16" />
        </svg>
      </button>
    </div>
    <main
      class="relative flex-1 overflow-y-scroll focus:outline-none"
      tabindex="0">
      <div class="pt-2 pb-6 md:py-6">
        <main class="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
          <slot />
        </main>
      </div>
    </main>
  </div>
</div>
