<script>
import { createEventDispatcher } from 'svelte'
const dispatch = createEventDispatcher()

export let title
export let selected = false
export let required = false
export let details = false
export let widgets = true
export let edit = false

let modal = false
const toggleModal = () => (modal = !modal)

const deleteSelected = () => (toggleModal(), dispatch('delete'))
const openSidebar = () => dispatch('open')
const use = () => dispatch('use')
</script>

<div class="mb-16 md:flex md:items-center md:justify-between">
  <div class="flex-1 min-w-0">
    <h2
      class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
      {title}
    </h2>
    {#if details}
      <div class="flex flex-col mt-1 sm:mt-0 sm:flex-row sm:flex-wrap">
        {#if details.quiz}
          <div
            class="flex items-center mt-2 text-sm leading-5 text-gray-500 sm:mr-6">
            <svg
              class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95
              22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0
              011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                clip-rule="evenodd" />
              <path
                d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796
              0-5.487-.46-8-1.308z" />
            </svg>
            {details.quiz}
          </div>
        {/if}
        {#if details.venue}
          <div
            class="flex items-center mt-2 text-sm leading-5 text-gray-500 sm:mr-6">
            <svg
              class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2
              0 000 4z"
                clip-rule="evenodd" />
            </svg>
            {details.venue}
          </div>
        {/if}
        {#if details.date}
          <div
            class="flex items-center mt-2 text-sm leading-5 text-gray-500 sm:mr-6">
            <svg
              class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0
              00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clip-rule="evenodd" />
            </svg>
            {details.date}
          </div>
        {/if}
        {#if details.team}
          <div
            class="flex items-center mt-2 text-sm leading-5 text-gray-500 sm:mr-6">
            <svg
              class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
                clip-rule="evenodd" />
            </svg>
            {details.team}
          </div>
        {/if}
        {#if details.round}
          <div
            class="flex items-center mt-2 text-sm leading-5 text-gray-500 sm:mr-6">
            <svg
              class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor">
              <path
                fill-rule="evenodd"
                d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                clip-rule="evenodd" />
            </svg>
            {details.round}
          </div>
        {/if}
      </div>
    {/if}
  </div>
  {#if widgets}
    <div
      class="fixed z-40 top-0 right-0 px-4 py-2 md:mt-5 md:mr-5 flex gap-1 sm:gap-3 items-center bg-white shadow-lg rounded-lg">
      {#if selected}
        <span
          class="text-xs sm:text-sm italic sm:font-medium sm:leading-5 text-gray-600"
          >{selected}
          selected</span>
        {#if required && selected == required}
          <span class="rounded-md shadow-sm">
            <button
              on:click={use}
              type="button"
              class="inline-flex items-center px-2 sm:px-4 py-1 sm:py-2 text-xs sm:text-sm sm:font-medium sm:leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50">
              Use
            </button>
          </span>
        {/if}

        {#if edit && selected == 1}
          <span class="rounded-md shadow-sm">
            <button
              on:click={() => dispatch('edit')}
              type="button"
              class="inline-flex items-center px-2 sm:px-4 py-1 sm:py-2 text-xs sm:text-sm sm:font-medium sm:leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50">
              Edit
            </button>
          </span>
        {/if}

        <span class="rounded-md shadow-sm">
          <button
            on:click={toggleModal}
            type="button"
            class="inline-flex items-center px-2 sm:px-4 py-1 sm:py-2 text-xs sm:text-sm sm:font-medium sm:leading-5 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50">
            Delete
          </button>
        </span>
      {/if}
      <span class="rounded-md shadow-sm">
        <button
          on:click={openSidebar}
          type="button"
          class="inline-flex items-center px-2 sm:px-4 py-1 sm:py-2 text-xs sm:text-sm sm:font-medium sm:leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:shadow-outline-purple focus:border-purple-500 active:bg-purple-500">
          <svg
            class="w-5 h-5 mr-1 sm:mr-2 -ml-1"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            stroke="currentColor">
            <path
              d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414
              5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
          </svg>
          New
        </button>
      </span>
    </div>
  {/if}
</div>

{#if modal}
  <div class="fixed inset-0 z-20 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
      <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75" />
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" />
      &#8203;
      <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
      <div
        class="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline">
        <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
          <button
            type="button"
            on:click={toggleModal}
            class="text-gray-400 transition duration-150 ease-in-out hover:text-gray-500 focus:outline-none focus:text-gray-500"
            aria-label="Close">
            <svg
              class="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="sm:flex sm:items-start">
          <div
            class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
            <svg
              class="w-6 h-6 text-red-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732
                4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3
              class="text-lg font-medium leading-6 text-gray-900"
              id="modal-headline">
              Delete
              {selected}
              {selected > 1 ? 'items' : 'item'}
            </h3>
            <div class="mt-2">
              <p class="text-sm leading-5 text-gray-500">
                {#if selected > 1}
                  Are you sure you want to delete these items? They cannot be
                  restored.
                {:else}
                  Are you sure you want to delete this item? It cannot be
                  restored.
                {/if}
              </p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              on:click={deleteSelected}
              type="button"
              class="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red sm:text-sm sm:leading-5">
              Delete
            </button>
          </span>
          <span class="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button
              on:click={toggleModal}
              type="button"
              class="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5">
              Cancel
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
{/if}
