<script>
import { createEventDispatcher } from 'svelte'
const dispatch = createEventDispatcher()

function close() {
  dispatch('close')
}

export let name = 'dashboard'
export let live = false
import NavIcon from './NavIcon.svelte'
</script>

{#if live}
  <a
    href={`/live/${name}`}
    on:click={close}
    class="flex items-center px-2 py-2 mt-1 text-sm font-medium leading-5 capitalize transition duration-150 ease-in-out rounded-md group text-cool-gray-300 hover:text-cool-gray-50 hover:bg-cool-gray-800 focus:outline-none focus:text-cool-gray-50 focus:bg-cool-gray-800">
    <NavIcon name={name} />
    {name}
  </a>
{:else}
  <a
    href={'/' + name}
    on:click={close}
    class="flex items-center px-2 py-2 mt-1 text-sm font-medium leading-5 capitalize transition duration-150 ease-in-out rounded-md group text-cool-gray-300 hover:text-cool-gray-50 hover:bg-cool-gray-800 focus:outline-none focus:text-cool-gray-50 focus:bg-cool-gray-800">
    <NavIcon name={name} />
    {name}
  </a>
{/if}
