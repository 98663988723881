<script>
export let label

export let tags = []
let tag = ''

function keyup(e) {
  if (e.keyCode == 32) {
    let newTag = tag.trim().toLowerCase()
    if (newTag) {
      if (!tags.includes(newTag)) {
        tags = [...tags, newTag]
        tag = ' '
      } else {
        tag = tag.trimEnd()
      }
    }
  }
  if (e.keyCode == 8 && !tag) {
    tags.pop()
    tags = tags
    tag = ' '
  }
}

function focusout() {
  let newTag = tag.trim().toLowerCase()
  if (newTag) {
    if (!tags.includes(newTag)) {
      tags = [...tags, newTag]
      tag = ' '
    }
  }
}
</script>

<div
  class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
  <label
    for="tags"
    class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
    {label}
  </label>
  <div class="mt-1 sm:mt-0 sm:col-span-2">
    <div class="max-w-lg rounded-md shadow-sm">
      <div class="relative rounded-md shadow-sm">
        <div
          class="w-full transition duration-150 ease-in-out form-input sm:text-sm sm:leading-5">
          {#each tags as tag}
            <div
              class="inline-flex px-2 mb-2 mr-2 text-white bg-purple-500 rounded-lg ">
              {tag}
            </div>
          {/each}
          <input
            class="inline-flex min-w-0 mb-2"
            bind:value={tag}
            type="text"
            size="8"
            on:keyup={keyup}
            on:focusout={focusout} />
        </div>
      </div>
    </div>
  </div>
</div>
