<script>
import formatRelative from 'date-fns/formatRelative'
import { enGB } from 'date-fns/locale'
import { Doc } from 'sveltefire'
import Header from '../../components/Header.svelte'
export let user, params, eventData, eventRef

let eventDetails, quizData

function dateString(date) {
  let string = formatRelative(date.toDate(), new Date(Date.now()), {
    locale: enGB,
  })
  string = string.charAt(0).toUpperCase() + string.slice(1)
  return string
}

$: {
  if (eventData) {
    eventDetails = {
      venue: eventData.venue.name,
      quiz: eventData.quiz.name,
      date: dateString(eventData.date),
    }
  }
}

function setQuizData({ detail: { data } }) {
  if (data) quizData = data
}

async function lock(section, lock) {
  eventData.sections[section].locked = lock
  await eventRef.set(eventData)
}

let index = 1
function getIndex() {
  return index++
}
</script>

{#if eventData && eventDetails}
  <Header
    title={`Quiz (${eventRef.id})`}
    widgets={false}
    details={eventDetails} />
{/if}

{#if eventData && quizData}
  {#each eventData.sections as section, sindex}
    <div class="mt-16">
      <div class="mb-5">
        {#if section.locked}
          <div class="flex">
            <svg viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 mr-2"
              ><path
                fill-rule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                clip-rule="evenodd" />
            </svg>
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              {section.name}
            </h3>
          </div>
          <p class="mt-2 text-sm leading-5 text-gray-500">
            Round locked.
            <span
              on:click={() => lock(sindex, false)}
              class="italic cursor-pointer hover:underline">Unlock</span>
          </p>
        {:else}
          <div class="flex">
            <svg
              viewBox="0 0 20 20"
              fill="currentColor"
              class="w-6 h-6 mr-2 text-green-700"
              ><path
                d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z" /></svg>
            <h3 class="text-lg font-medium leading-6 text-green-700">
              {section.name}
            </h3>
          </div>
          <p class="mt-2 text-sm leading-5 text-gray-500">
            Round unlocked.
            <span
              on:click={() => lock(sindex, true)}
              class="italic cursor-pointer hover:underline">Lock</span>
          </p>
        {/if}
      </div>
      <dl>
        {#each quizData.sections[sindex].rounds as round}
          {#if round.named}
            <div class="pb-2 mt-4">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                {round.name}
              </h3>
            </div>
          {/if}
          {#if round.type == 'text' && round.value}
            <div
              class="pt-5 pb-5 border-t border-gray-200 sm:grid sm:grid-cols-12 sm:gap-4">
              <dt class="text-sm font-medium leading-5 text-gray-500" />
              <dd
                class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-6">
                <h3
                  class="text-sm font-medium leading-5 text-gray-900 capitalize">
                  {round.of.replace('_', ' ')}
                </h3>
                <p class="mt-1 text-sm leading-5 text-gray-500">
                  {round.value}
                </p>
              </dd>
            </div>
          {/if}
          {#each round.parts as part, pindex}
            <div
              class="pt-5 pb-5 border-t border-gray-200 sm:grid sm:grid-cols-12 sm:gap-4
              {round.answerable ? '' : 'bg-purple-50'}">
              <dt class="text-sm font-medium leading-5 text-gray-500">
                {#if section.of == 'questions' && round.answerable}
                  {getIndex()}.
                {:else if section.of == 'pictures'}{pindex + 1}{/if}
              </dt>
              <dd
                class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-6">
                <h3 class="text-sm font-medium leading-5 text-gray-900">
                  {#if round.type == 'questions'}
                    {part.question}
                  {:else if round.type == 'pictures'}
                    {part.prompt}
                  {:else if round.type == 'music'}
                    {part.artist}
                    -
                    {part.title}
                    ({part.year})
                  {/if}
                </h3>
                <p class="mt-1 text-sm leading-5 text-gray-500">
                  {#if round.type == 'questions'}
                    {part.answer}
                  {:else if round.type == 'pictures'}
                    {part.answer}
                    <details class="mt-1">
                      <summary class="cursor-pointer">Picture</summary>
                      <img
                        class="mt-2 w-full h-auto pr-4"
                        alt={part.answer}
                        src={part.url} />
                    </details>
                  {:else if round.type == 'music'}{part.url}{/if}
                </p>
              </dd>
              {#if part.saved}
                <div
                  class="text-sm italic leading-5 text-gray-500 sm:col-span-5">
                  {#each part.saved as answer}
                    <p>"{answer}"</p>
                  {/each}
                </div>
              {/if}
            </div>
          {/each}
        {/each}
      </dl>
    </div>
  {/each}
{/if}

{#if eventData}
  <Doc
    path={`org/${user.org}/quizzes/${eventData.quiz.id}`}
    on:data={setQuizData}>
    <div slot="loading" class="flex justify-center mt-8 mb-5"><span /></div>
  </Doc>
{/if}
