<script>
import TextField from '../../components/forms/TextField.svelte'
import Tags from '../../components/forms/Tags.svelte'
import { createEventDispatcher, getContext } from 'svelte'
export let user

const dispatch = createEventDispatcher()
const db = getContext('firebase').getFirebase().firestore()
const collection = db.collection(`org/${user.org}/questions`)

const template = () => {
  return { question: '', answer: '', tags: [] }
}

let newQuestions = [template()]
let loading = false

const add = () => (newQuestions = [...newQuestions, template()])

async function save() {
  loading = true
  const uploads = newQuestions.map((question) => {
    question.date = new Date(Date.now())
    question.author = user.name
    question.quizzes = []
    question.events = []
    question.saved = []
    return collection.add(question)
  })
  await Promise.all(uploads)
  dispatch('goto', {
    page: '/questions',
  })
}

function cancel() {
  dispatch('goto', {
    page: '/questions',
  })
}
</script>

<div class="mb-16 lg:flex lg:items-center lg:justify-between">
  <div class="flex-1 min-w-0">
    <h2
      class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
      New Questions
    </h2>
  </div>
</div>
{#if !loading}
  <form>
    <div>
      {#each newQuestions as question, index}
        <div class="pt-8 mt-8 border-t border-gray-200 sm:mt-5 sm:pt-10">
          <div>
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              Question
              {index + 1}
            </h3>
          </div>
          <div class="mt-6 sm:mt-5">
            <TextField
              bind:value={question.question}
              label="Question"
              wide={true} />
          </div>
          <div class="mt-6 sm:mt-5">
            <TextField bind:value={question.answer} label="Answer" />
          </div>
          <div class="mt-6 sm:mt-5">
            <Tags bind:tags={question.tags} label="Tags" index="index" />
          </div>
        </div>
      {/each}
      <div class="mt-8 mb-4 space-y-4">
        <div class="flex justify-center text-sm leading-5">
          <a
            on:click|preventDefault={add}
            href="."
            class="inline-flex items-center space-x-2 font-medium text-purple-600 transition duration-150 ease-in-out group hover:text-purple-500">
            <svg
              class="w-5 h-5 text-purple-500 transition duration-150 ease-in-out group-hover:text-purple-400"
              fill="currentColor"
              viewBox="0 0 20 20">
              <path
                fill-rule="evenodd"
                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0
                110-2h5V4a1 1 0 011-1z"
                clip-rule="evenodd" />
            </svg>
            <span>Add another</span>
          </a>
        </div>
      </div>
    </div>

    {#if collection}
      <div class="pt-5 mt-8 border-t border-gray-200">
        <div class="flex justify-end">
          <span class="inline-flex rounded-md shadow-sm">
            <button
              on:click|once|preventDefault={cancel}
              type="button"
              class="px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800">
              Cancel
            </button>
          </span>
          <span class="inline-flex ml-3 rounded-md shadow-sm">
            <button
              on:click|once|preventDefault={save}
              class="inline-flex justify-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:border-purple-700 focus:shadow-outline-purple active:bg-purple-700">
              Save
            </button>
          </span>
        </div>
      </div>
    {/if}
  </form>
{:else}
  <div class="flex justify-center w-full mt-8 mb-5"><span /></div>
{/if}
