<script>
export let label
export let placeholder = ''
export let value = ''
</script>

<div
  class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
  <label
    for="username"
    class="block text-sm font-medium leading-5 text-gray-700 capitalize sm:mt-px sm:pt-2">
    {label}
  </label>
  <div class="mt-1 sm:mt-0 sm:col-span-2">
    <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
      <input
        id="first_name"
        type="number"
        bind:value
        placeholder={placeholder}
        class="block w-full transition duration-150 ease-in-out form-input sm:text-sm sm:leading-5" />
    </div>
  </div>
</div>
