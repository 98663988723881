<script>
  // FIREBASE
  import { FirebaseApp, User, Doc } from 'sveltefire'
  import firebase from 'firebase/app'
  import 'firebase/firestore'
  import 'firebase/storage'
  import 'firebase/auth'
  firebase.initializeApp({
    apiKey: 'AIzaSyAiWUwzC_b00Et9lXwVa3Bki0Bi_OqK9vE',
    authDomain: 'quiz-dex.firebaseapp.com',
    databaseURL: 'https://quiz-dex.firebaseio.com',
    projectId: 'quiz-dex',
    storageBucket: 'quiz-dex.appspot.com',
    messagingSenderId: '216630950217',
    appId: '1:216630950217:web:83f2e8f62f362e752f4298',
    measurementId: 'G-SKHSTELHM2',
  })

  // LAYOUTS
  import Login from './Login.svelte'
  import Layout from './Layout.svelte'

  // ROUTER
  import Router from './Router.svelte'
</script>

<FirebaseApp {firebase}>
  <User persist={localStorage} let:user let:auth>
    <div slot="signed-out">
      <Login {auth} />
    </div>
    <Layout {auth}>
      <Doc path={`user/${user.uid}`} let:data={user}>
        <Router {user} />
      </Doc>
    </Layout>
  </User>
</FirebaseApp>
