<script>
import { Collection } from 'sveltefire'

export let user
export let collection
export let label
export let value

let show = false

let options = []
let last

function select(option) {
  show = toggleShow()
  value = option
}

function toggleShow() {
  show = !show
}

let pageSize = 10
let query = (ref) => ref.orderBy('date', 'desc').limit(pageSize)

function loadMore() {
  query = (ref) =>
    ref.orderBy('date', 'desc').startAfter(last['date']).limit(pageSize)
}
function mergeData({ detail: { data } }) {
  if (data) {
    last = data[data.length - 1]
    options = [...options, ...data]
  }
}
</script>

<div
  class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
  <label
    for="date"
    class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
    {label}
  </label>
  <div class="mt-1 sm:mt-0 sm:col-span-2">
    <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
      <span class="inline-block w-full rounded-md shadow-sm">
        <button
          type="button"
          on:click={toggleShow}
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          class="relative w-full py-2 pl-3 pr-10 text-left transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md cursor-default focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5">
          <span class="block truncate">
            {#if value}{value.name}{:else}Choose...{/if}
          </span>
          <span
            class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <svg
              class="w-5 h-5 text-gray-400"
              viewBox="0 0 20 20"
              fill="none"
              stroke="currentColor">
              <path
                d="M7 7l3-3 3 3m0 6l-3 3-3-3"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
          </span>
        </button>
      </span>

      {#if show}
        <div
          class="absolute z-20 w-full max-w-lg mt-1 bg-white rounded-md shadow-lg sm:max-w-xs">
          <ul
            tabindex="-1"
            role="listbox"
            aria-labelledby="listbox-label"
            aria-activedescendant="listbox-item-3"
            class="py-1 overflow-auto text-base leading-6 rounded-md shadow-xs max-h-60 focus:outline-none sm:text-sm sm:leading-5">
            {#each options as option}
              <li
                id="listbox-option-0"
                on:click={() => {
                  select(option)
                }}
                role="option"
                class="relative py-2 pl-3 text-gray-900 cursor-default select-none pr-9 hover:bg-purple-200">
                <span class="block font-normal truncate">{option.name}</span>
              </li>
            {/each}
            {#if options.length % pageSize == 0}
              <li
                id="listbox-option-0"
                on:click={() => {
                  loadMore()
                }}
                role="option"
                class="relative py-2 pl-3 text-gray-900 cursor-default select-none pr-9 hover:bg-purple-200">
                <span class="block font-normal truncate">More...</span>
              </li>
            {/if}
          </ul>
        </div>
      {/if}
    </div>
  </div>
</div>

<Collection
  path={`org/${user.org}/${collection}`}
  once={true}
  query={query}
  on:data={mergeData} />
