<script>
  export let label, options
  export let value
</script>

<div
  class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
  <label for={label} class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
    {label}
  </label>
  <div class="mt-1 sm:mt-0 sm:col-span-2">
    <div class="max-w-lg rounded-md shadow-sm sm:max-w-xs">
      <select
        id={label}
        bind:value
        class="block w-full transition duration-150 ease-in-out form-select sm:text-sm sm:leading-5">
        {#each options as option, index}
          <option value={index}>{option}</option>
        {/each}
      </select>
    </div>
  </div>
</div>
