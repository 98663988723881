<script>
import Sortable from '../Sortable.svelte'

export let name, num, list

export let sortedList = list

function sortList(event) {
  sortedList = event.detail
}

function reset() {
  sortedList = []
}
</script>

<div
  class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
  <label
    for="last_name"
    class="block text-sm font-medium leading-5 text-gray-700 capitalize sm:mt-px sm:pt-2">
    {num > 1 ? `${name}s` : name}
  </label>
  <div class="mt-2 sm:mt-0 sm:col-span-2">
    <div class="flex justify-center max-w-lg">
      <Sortable
        list={list}
        key={'id'}
        type={name}
        on:sort={(e) => sortList(e)} />
    </div>
    <div class="max-w-lg mt-4 text-sm text-center text-gray-600">
      <button
        on:click={() => reset()}
        type="button"
        class="font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:outline-none focus:underline">
        Reset
      </button>
    </div>
  </div>
</div>
