<script>
import { Doc } from 'sveltefire'
import Header from '../../components/Header.svelte'
import { createEventDispatcher, getContext } from 'svelte'
const dispatch = createEventDispatcher()
export let user, params, eventData, eventRef
const db = getContext('firebase').getFirebase().firestore()
const quizzes = db.collection(`org/${user.org}/quizzes`)

let submissionData,
  submissionRef,
  quizData,
  teamData,
  teamRef,
  markedData,
  markedRef

let marks = {}
let previousScore = 0

function calculateScore(_marks) {
  let points = 0
  Object.keys(_marks).forEach((key) => {
    points += Number(_marks[key])
  })
  return points
}

async function save() {
  const batch = db.batch()

  const points = calculateScore(marks)
  teamData.score = teamData.score + (points - previousScore)
  if (!teamData.submissions) teamData.submissions = {}
  teamData.submissions[params.index] = marks
  batch.set(teamRef, teamData)

  // submissionData.sections[params.index].marked = true
  // submissionData.sections[params.index].date = new Date(Date.now())
  // batch.set(submissionRef, submissionData)

  markedData[params.index][params.team] = true
  batch.set(markedRef, markedData)

  await batch.commit()
  dispatch('goto', {
    page: '/live/submissions',
  })
}

async function unsubmit() {
  submissionData.sections[params.index].submitted = false
  submissionRef.set(submissionData)
  dispatch('goto', {
    page: '/live/submissions',
  })
}

function cancel() {
  dispatch('goto', {
    page: '/live/submissions',
  })
}

async function saveAnswer(qid, type, answer) {
  const collections = {
    questions: db.collection(`org/${user.org}/questions`),
    pictures: db.collection(`org/${user.org}/pictures`),
    music: db.collection(`org/${user.org}/music`),
  }
  const questionRef = collections[type].doc(qid)
  const questionData = await questionRef.get().then((doc) => doc.data())
  if (questionData.saved.includes(answer)) {
    questionData.saved = questionData.saved.filter((x) => x != answer)
  } else {
    questionData.saved.push(answer)
  }
  const batch = db.batch()
  const prebatch = {}
  batch.set(questionRef, questionData)
  questionData.quizzes.forEach((quiz) => {
    if (!prebatch[quiz.id]) {
      prebatch[quiz.id] = []
    }
    prebatch[quiz.id].push(quiz)
  })
  await Promise.all(
    Object.keys(prebatch).map(async (id) => {
      const docRef = quizzes.doc(id)
      const docData = await docRef.get().then((doc) => doc.data())
      if (docData) {
        prebatch[id].forEach((q) => {
          docData.sections[q.section].rounds[q.round].parts[q.part].saved =
            questionData.saved
        })
        batch.set(docRef, docData)
      }
    }),
  )
  await batch.commit()
}

function setQuizData({ detail: { data } }) {
  if (data) {
    data.sections[params.index].rounds = data.sections[params.index].rounds
      .filter((round) => round.answerable)
      .filter((round) => {
        if (!round.optional) return true
        if (round.value) return true
        if (round.parts.length) return true
        return false
      })
    quizData = data.sections[params.index]
  }
}
function setSubmissionData({ detail: { data } }) {
  if (data) {
    submissionData = data
  }
}
function setSubmissionRef({ detail: { ref } }) {
  if (ref) {
    submissionRef = ref
  }
}
function setTeamData({ detail: { data } }) {
  if (data) {
    teamData = data
    if (teamData.submissions?.[params.index]) {
      marks = teamData.submissions[params.index]
      previousScore = calculateScore(marks)
    }
  }
}
function setTeamRef({ detail: { ref } }) {
  if (ref) {
    teamRef = ref
  }
}

function setMarkedData({ detail: { data } }) {
  if (data) {
    markedData = data
  } else {
    markedData = { 0: {}, 1: {}, 2: {}, 3: {} }
  }
}
function setMarkedRef({ detail: { ref } }) {
  if (ref) {
    markedRef = ref
  }
}
</script>

<Header
  title={'Mark Submission'}
  details={submissionData
    ? {
        team: submissionData.sections[params.index].team.name,
        round: eventData.sections[params.index].name,
      }
    : false}
  widgets={false} />

{#if eventData && submissionData && quizData && teamData && markedData}
  <div class="flex flex-col mt-8">
    <div class="inline-block min-w-full py-4 align-middle sm:px-6">
      <table class="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th
              class="px-1 sm:px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase">
              #
            </th>
            <th
              class="px-1 sm:px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase">
              Answer
            </th>
            <th
              class="px-1 sm:px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase">
              Mark
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          {#each eventData.sections[params.index].rounds as round, rindex}
            {#each round.parts as part, pindex}
              <tr>
                <td class="px-1 sm:px-6 py-4 text-sm leading-5 text-gray-500">
                  {part.index}
                </td>
                <td
                  class="px-1 sm:px-6 py-4 text-sm font-medium leading-5 text-gray-500">
                  {#if submissionData.sections[params.index].answers[part.index]}
                    <p
                      on:click={() =>
                        saveAnswer(
                          part.id,
                          round.type,
                          submissionData.sections[params.index].answers[
                            part.index
                          ],
                        )}
                      class="text-sm font-medium leading-5 text-gray-900 cursor-pointer">
                      {submissionData.sections[params.index].answers[
                        part.index
                      ]}
                      {#if submissionData.sections[params.index].joker == part.index}
                        <span class="ml-1 font-bold text-green-600"
                          >(JOKER)</span>
                      {/if}
                      {#if submissionData.sections[params.index].banker == part.index}
                        <span class="ml-1 font-bold text-green-800"
                          >(BANKER)</span>
                      {/if}
                      {#if quizData.rounds[rindex].parts[pindex].saved.includes(submissionData.sections[params.index].answers[part.index])}
                        (SAVED)
                      {/if}
                    </p>
                  {:else}BLANK
                    {#if submissionData.sections[params.index].joker == part.index}
                      <span class="ml-1 font-bold text-green-600">(JOKER)</span>
                    {/if}
                    {#if submissionData.sections[params.index].banker == part.index}
                      <span class="ml-1 font-bold text-green-600"
                        >(BANKER)</span>
                    {/if}
                  {/if}
                  <p class="text-sm italic leading-5 text-gray-500">
                    {#if round.of == 'question' || round.of == 'picture'}
                      {quizData.rounds[rindex].parts[pindex].answer}
                    {:else if round.of == 'song'}
                      {quizData.rounds[rindex].parts[pindex].artist}
                      -
                      {quizData.rounds[rindex].parts[pindex].title}
                      ({quizData.rounds[rindex].parts[pindex].year})
                    {/if}
                  </p>
                </td>
                <td
                  class="px-1 sm:px-6 py-4 text-sm font-medium leading-5 text-gray-500">
                  <span class="relative inline-flex">
                    <input
                      id="first_name"
                      type="number"
                      bind:value={marks[part.index]}
                      placeholder={0}
                      class="w-16 mr-2 relative inline-flex items-center px-4 py-2 shadow-sm rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" />

                    <span class="relative z-0 inline-flex shadow-sm rounded-md">
                      <button
                        on:click={() => (marks[part.index] = 1)}
                        type="button"
                        class="relative inline-flex items-center px-3 sm:px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                        1
                      </button>
                      <button
                        on:click={() => (marks[part.index] = 2)}
                        type="button"
                        class="-ml-px relative inline-flex items-center px-3 sm:px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                        2
                      </button>
                      <button
                        on:click={() => (marks[part.index] = 3)}
                        type="button"
                        class="-ml-px relative inline-flex items-center px-3 sm:px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                        3
                      </button>
                      <button
                        on:click={() => (marks[part.index] = 4)}
                        type="button"
                        class="-ml-px relative inline-flex items-center px-3 sm:px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                        4
                      </button>
                    </span>
                  </span>
                </td>
              </tr>
            {/each}

            <!-- 

              FOR SPECIAL CASES!

             -->
            {#if round.type == 'text'}
              <tr>
                <td class="px-1 sm:px-6 py-4 text-sm leading-5 text-gray-500">
                  {round.of.replace('_', ' ')}
                </td>
                <td
                  class="px-1 sm:px-6 py-4 text-sm font-medium leading-5 text-gray-500">
                  {#if submissionData.sections[params.index].answers[round.of]}
                    <p class="text-sm font-medium leading-5 text-gray-900">
                      {submissionData.sections[params.index].answers[round.of]}
                      {#if submissionData.sections[params.index].joker == round.of}
                        <span class="font-bold text-green-800">(JOKER)</span>
                      {/if}
                      {#if submissionData.sections[params.index].banker == round.of}
                        <span class="font-bold text-green-800">(BANKER)</span>
                      {/if}
                    </p>
                  {:else}BLANK{/if}
                  <p class="text-sm italic leading-5 text-gray-500">
                    {quizData.rounds[rindex].value}
                  </p>
                </td>
                <td
                  class="px-1 sm:px-6 py-4 text-sm font-medium leading-5 text-gray-500">
                  <span class="relative inline-flex">
                    <input
                      id="first_name"
                      type="number"
                      bind:value={marks[round.of]}
                      placeholder={0}
                      class="w-16 mr-2 relative inline-flex items-center px-4 py-2 shadow-sm rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500" />

                    <span class="relative z-0 inline-flex shadow-sm rounded-md">
                      <button
                        on:click={() => (marks[round.of] = 1)}
                        type="button"
                        class="relative inline-flex items-center px-3 sm:px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                        1
                      </button>
                      <button
                        on:click={() => (marks[round.of] = 2)}
                        type="button"
                        class="-ml-px relative inline-flex items-center px-3 sm:px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                        2
                      </button>
                      <button
                        on:click={() => (marks[round.of] = 3)}
                        type="button"
                        class="-ml-px relative inline-flex items-center px-3 sm:px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                        3
                      </button>
                      <button
                        on:click={() => (marks[round.of] = 4)}
                        type="button"
                        class="-ml-px relative inline-flex items-center px-3 sm:px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                        4
                      </button>
                    </span>
                  </span>
                </td>
              </tr>
            {/if}
          {/each}
        </tbody>
      </table>
    </div>
  </div>
  <div class="pt-5 mt-5 border-t border-gray-200">
    <div class="flex">
      {#if !previousScore}
        <span class="inline-flex rounded-md shadow-sm">
          <button
            on:click|once|preventDefault={unsubmit}
            type="button"
            class="px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800">
            Unsubmit
          </button>
        </span>
      {/if}
      <span class="ml-auto inline-flex rounded-md shadow-sm">
        <button
          on:click|once|preventDefault={cancel}
          type="button"
          class="px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800">
          Cancel
        </button>
      </span>

      <span class="inline-flex ml-3 rounded-md shadow-sm">
        <button
          on:click|once|preventDefault={save}
          class="inline-flex justify-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:border-purple-700 focus:shadow-outline-purple active:bg-purple-700">
          Save
        </button>
      </span>
    </div>
  </div>
{/if}

{#if eventData}
  <Doc
    path={`org/${user.org}/events/${eventRef.id}/submissions/${params.team}`}
    on:data={setSubmissionData}
    on:ref={setSubmissionRef} />

  <Doc
    path={`org/${user.org}/quizzes/${eventData.quiz.id}`}
    on:data={setQuizData} />

  <Doc
    path={`org/${user.org}/events/${eventRef.id}/teams/${params.team}`}
    on:data={setTeamData}
    on:ref={setTeamRef} />

  <Doc
    path={`org/${user.org}/events/${eventRef.id}/submissions/marked`}
    on:data={setMarkedData}
    on:ref={setMarkedRef} />
{/if}
