<script>
import { Collection } from 'sveltefire'
import Header from '../../components/Header.svelte'

export let user, eventData, eventRef

let teamsData = []

function setData({ detail: { data } }) {
  if (data) {
    teamsData = data
  }
}
</script>

{#if eventData}
  <Header title={`Teams (${eventRef.id})`} widgets={false} />
{/if}

{#if teamsData.length}
  <div class="flex flex-col mt-8">
    <div class="inline-block min-w-full py-4 align-middle sm:px-6">
      <table class="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase ">
              Name
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase ">
              Players
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase ">
              Score
            </th>
            <th
              class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase ">
              Action
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          {#each teamsData as team}
            <tr>
              <td class="px-6 py-4 text-sm leading-5 text-gray-500">
                {team.name}
              </td>
              <td class="px-6 py-4 text-sm font-medium leading-5 text-gray-500">
                {team.number}
              </td>
              <td class="px-6 py-4 text-sm font-medium leading-5 text-gray-500">
                {team.score}
              </td>
              <td class="px-6 py-4 text-sm font-medium leading-5 text-gray-500">
                <a
                  href={`/live/teams/edit/${team.id}`}
                  class="text-purple-600 cursor-pointer hover:text-purple-900">
                  Edit
                </a>
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    </div>
  </div>
{/if}

{#if eventData}
  <Collection
    path={`org/${user.org}/events/${eventRef.id}/teams`}
    query={(ref) => ref.orderBy('score')}
    on:data={setData} />
{/if}
