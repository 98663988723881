<script>
let isOver = false

const getDraggedParent = (node) =>
  node.dataset && node.dataset.index
    ? node.dataset
    : getDraggedParent(node.parentNode)

const start = (ev) => {
  ev.dataTransfer.setData('source', ev.target.dataset.index)
}
const over = (ev) => {
  ev.preventDefault()
  let dragged = getDraggedParent(ev.target)
  if (isOver !== dragged.idx) isOver = JSON.parse(dragged.idx)
}

const leave = (ev) => {
  let dragged = getDraggedParent(ev.target)
  if (isOver === dragged.idx) isOver = false
}
const drop = (ev) => {
  isOver = false
  ev.preventDefault()
  let dragged = getDraggedParent(ev.target)
  let from = ev.dataTransfer.getData('source')
  let to = dragged.index
  reorder({ from, to })
}

// DISPATCH REORDER
import { createEventDispatcher } from 'svelte'
const dispatch = createEventDispatcher()
const reorder = ({ from, to }) => {
  let newList = [...list]

  newList.splice(to, 0, newList.splice(from, 1)[0])

  dispatch('sort', newList)
}
// UTILS

const getKey = (item) => (key ? item[key] : item)

const getName = (item) => {
  if (type == 'question') return item.question
  if (type == 'song') return `${item.artist} - ${item.title} (${item.year})`
  if (type == 'picture') return `${item.prompt} (${item.answer})`
}

// PROPS
export let list
export let key
export let type
</script>

{#if list && list.length}
  <ul>
    {#each list as item, index (getKey(item))}
      <li
        class="block px-4 py-2 text-sm leading-5 text-gray-700 cursor-move hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
        data-index={index}
        data-idx={JSON.stringify(getKey(item))}
        draggable="true"
        on:dragstart={start}
        on:dragover={over}
        on:dragleave={leave}
        on:drop={drop}
        class:over={getKey(item) === isOver}>
        <slot item={item} index={index}>
          <p>{index + 1}. {getName(item)}</p>
        </slot>
      </li>
    {/each}
  </ul>
{/if}

<style>
ul {
  list-style: none;
  padding: 0;
}
li {
  border: 2px dotted transparent;
  transition: border 0.1s linear;
}
.over {
  border-color: rgba(48, 12, 200, 0.2);
}
</style>
