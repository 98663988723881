import { writable } from 'svelte/store'

// const storage = {
//   context: localStorage.getItem('context'),
//   newQuiz: localStorage.getItem('newQuiz'),
//   live: localStorage.getItem('live')
// }
// const use = {
//   context: storage.context ? JSON.parse(storage.context) : null,
//   newQuiz: storage.newQuiz ? JSON.parse(storage.newQuiz) : null,
//   live: storage.live ? JSON.parse(storage.live) : null
// }

const stored = localStorage.getItem('live')
const use = stored ? JSON.parse(stored) : null

export const context = writable(null)
export const newQuiz = writable(null)
export const live = writable(use)

// newQuiz.subscribe(obj => {
//   localStorage.setItem('newQuiz', JSON.stringify(obj))
// })

// context.subscribe(obj => {
//   localStorage.setItem('context', JSON.stringify(obj))
// })

live.subscribe(obj => {
  localStorage.setItem('live', JSON.stringify(obj))
})