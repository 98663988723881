<script>
import { Doc } from 'sveltefire'
import TextField from '../../components/forms/TextField.svelte'
import Header from '../../components/Header.svelte'

import { createEventDispatcher, getContext } from 'svelte'
const dispatch = createEventDispatcher()
const db = getContext('firebase').getFirebase().firestore()

export let user, params

const quizzes = db.collection(`org/${user.org}/quizzes`)

let questionData, questionRef

function setQuestionData({ detail: { data } }) {
  if (data) {
    questionData = data
  }
}
function setQuestionRef({ detail: { ref } }) {
  if (ref) {
    questionRef = ref
  }
}

let loading = false

async function save() {
  loading = true

  const batch = db.batch()
  const prebatch = {}

  batch.set(questionRef, questionData)

  questionData.quizzes.forEach((quiz) => {
    if (!prebatch[quiz.id]) {
      prebatch[quiz.id] = []
    }
    prebatch[quiz.id].push(quiz)
  })

  await Promise.all(
    Object.keys(prebatch).map(async (id) => {
      const docRef = quizzes.doc(id)
      const docData = await docRef.get().then((doc) => doc.data())
      if (docData) {
        prebatch[id].forEach((q) => {
          docData.sections[q.section].rounds[q.round].parts[q.part].question =
            questionData.question
          docData.sections[q.section].rounds[q.round].parts[q.part].answer =
            questionData.answer
        })
        batch.set(docRef, docData)
      }
    }),
  )

  await batch.commit()
  dispatch('goto', {
    page: '/questions',
  })
}

function cancel() {
  dispatch('goto', {
    page: '/questions',
  })
}
</script>

<Header title={'Edit Question'} widgets={false} />

{#if questionData && !loading}
  <form>
    <div>
      <div class="pt-8 mt-8 border-t border-gray-200 sm:mt-5 sm:pt-10">
        <div>
          <h3 class="text-lg font-medium leading-6 text-gray-900">
            Question Data
          </h3>
        </div>
        <div class="mt-6 sm:mt-5">
          <TextField
            bind:value={questionData.question}
            label="Name"
            wide={true} />
        </div>
        <div class="mt-6 sm:mt-5">
          <TextField
            bind:value={questionData.answer}
            label="Name"
            wide={true} />
        </div>
      </div>
    </div>

    <div class="pt-5 mt-8 border-t border-gray-200">
      <div class="flex justify-end">
        <span class="inline-flex rounded-md shadow-sm">
          <button
            on:click={cancel}
            type="button"
            class="px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800">
            Cancel
          </button>
        </span>
        <span class="inline-flex ml-3 rounded-md shadow-sm">
          <button
            on:click|once|preventDefault={save}
            class="inline-flex justify-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:border-purple-700 focus:shadow-outline-purple active:bg-purple-700">
            Save
          </button>
        </span>
      </div>
    </div>
  </form>
{/if}

<Doc
  path={`org/${user.org}/questions/${params.id}`}
  once={true}
  on:data={setQuestionData}
  on:ref={setQuestionRef} />
