<script>
import { Doc } from 'sveltefire'
import TextField from '../../components/forms/TextField.svelte'
import NumField from '../../components/forms/NumField.svelte'
import Header from '../../components/Header.svelte'

import { createEventDispatcher } from 'svelte'
const dispatch = createEventDispatcher()

export let user, params, eventData, eventRef

let teamData, teamRef

function setTeamData({ detail: { data } }) {
  if (data) {
    teamData = data
  }
}
function setTeamRef({ detail: { ref } }) {
  if (ref) {
    teamRef = ref
  }
}

let loading = false
async function save() {
  loading = true
  await teamRef.set(teamData)
  dispatch('goto', {
    page: '/live/teams',
  })
}

function cancel() {
  dispatch('goto', {
    page: '/live/teams',
  })
}

let modal = false
const toggleModal = () => (modal = !modal)

async function deleteTeam() {
  await teamRef.delete()
  dispatch('goto', {
    page: '/live/teams',
  })
}
</script>

<Header
  title={teamData ? `Editing ${teamData.name}` : 'Edit Team'}
  widgets={false} />

{#if teamData && teamRef && !loading}
  <form>
    <div>
      <div class="pt-8 mt-8 border-t border-gray-200 sm:mt-5 sm:pt-10">
        <div>
          <h3 class="text-lg font-medium leading-6 text-gray-900">Team Data</h3>
        </div>
        <div class="mt-6 sm:mt-5">
          <TextField bind:value={teamData.name} label="Name" wide={true} />
        </div>
        <div class="mt-6 sm:mt-5">
          <TextField
            bind:value={teamData.number}
            label="Players"
            wide={false} />
        </div>
        <div class="mt-6 sm:mt-5">
          <NumField bind:value={teamData.score} label="Score" wide={false} />
        </div>
      </div>
    </div>

    <div class="pt-5 mt-8 border-t border-gray-200">
      <span class="flex justify-between">
        <span class="inline-flex rounded-md shadow-sm">
          <button
            on:click={toggleModal}
            type="button"
            class="inline-flex justify-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700">
            Delete
          </button>
        </span>
        <div class="flex justify-end">
          <span class="inline-flex rounded-md shadow-sm">
            <button
              on:click={cancel}
              type="button"
              class="px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800">
              Cancel
            </button>
          </span>
          <span class="inline-flex ml-3 rounded-md shadow-sm">
            <button
              on:click|once|preventDefault={save}
              class="inline-flex justify-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:border-purple-700 focus:shadow-outline-purple active:bg-purple-700">
              Save
            </button>
          </span>
        </div>
      </span>
    </div>
  </form>
{/if}

{#if modal}
  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div
      class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
      <!--
    Background overlay, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-gray-500 opacity-75" />
      </div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" />
      &#8203;
      <!--
    Modal panel, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      To: "opacity-100 translate-y-0 sm:scale-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100 translate-y-0 sm:scale-100"
      To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  -->
      <div
        class="inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline">
        <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
          <button
            type="button"
            on:click={toggleModal}
            class="text-gray-400 transition duration-150 ease-in-out hover:text-gray-500 focus:outline-none focus:text-gray-500"
            aria-label="Close">
            <svg
              class="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="sm:flex sm:items-start">
          <div
            class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
            <svg
              class="w-6 h-6 text-red-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732
              4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3
              class="text-lg font-medium leading-6 text-gray-900"
              id="modal-headline">
              Delete Team
            </h3>
            <div class="mt-2">
              <p class="text-sm leading-5 text-gray-500">
                Are you sure you want to delete this team? It cannot be
                restored.
              </p>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <button
              on:click={deleteTeam}
              type="button"
              class="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red sm:text-sm sm:leading-5">
              Delete
            </button>
          </span>
          <span class="flex w-full mt-3 rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button
              on:click={toggleModal}
              type="button"
              class="inline-flex justify-center w-full px-4 py-2 text-base font-medium leading-6 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5">
              Cancel
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
{/if}

{#if eventData}
  <Doc
    path={`org/${user.org}/events/${eventRef.id}/teams/${params.team}`}
    once={true}
    on:data={setTeamData}
    on:ref={setTeamRef} />
{/if}
