<script>
import { Collection } from 'sveltefire'
import Header from '../../components/Header.svelte'

import { createEventDispatcher } from 'svelte'
const dispatch = createEventDispatcher()

export let user
let venues = []
let selected = []
$: {
  selected = venues.filter((x) => x.selected)
}

function useVenue() {}

async function deleteVenues() {
  let toDelete = selected
  selected = []
  const deletions = toDelete.map((venue) => {
    return venue.ref.delete()
  })
  await Promise.all(deletions)
  let deletedIDs = toDelete.map((v) => v.id)
  venues = venues.filter((v) => !deletedIDs.includes(v.id))
}

function newVenues() {
  dispatch('goto', {
    page: '/venues/new',
  })
}

let pageSize = 10
let query = (ref) => ref.orderBy('date', 'desc').limit(pageSize)

function loadMore(last) {
  query = (ref) =>
    ref.orderBy('date', 'desc').startAfter(last['date']).limit(pageSize)
}

function mergeData({ detail: { data } }) {
  if (data) {
    venues = [...venues, ...data]
  }
}
</script>

<Header
  title="Venues"
  selected={selected.length}
  on:use={useVenue}
  on:delete={deleteVenues}
  on:open={newVenues} />

<ul>
  {#each venues as v}
    <li>
      <span
        on:click={() => {
          v.selected = !v.selected
        }}
        class="block {v.selected ? 'bg-purple-100 hover:bg-purple-100' : 'bg-white hover:bg-purple-50'}">
        <div class="px-4 py-4 sm:px-6">
          <div class="flex items-center justify-between">
            <div class="text-sm font-medium leading-5 text-gray-800">
              {v.name}
            </div>
            <div class="flex flex-shrink-0 ml-2" />
          </div>
          <div class="mt-2 sm:flex sm:justify-between">
            <div class="sm:flex">
              <div
                class="flex items-center mr-6 text-sm leading-5 text-gray-500">
                <span class="mr-6">{v.phone}</span>
                <span>{v.email}</span>
              </div>
            </div>
            <div
              class="flex items-center mt-2 text-sm leading-5 text-gray-500 sm:mt-0">
              <span />
            </div>
          </div>
        </div>
      </span>
    </li>
  {/each}
</ul>

<Collection
  path={`org/${user.org}/venues`}
  once={true}
  query={query}
  on:data={mergeData}
  let:last>
  {#if last}
    <div class="flex justify-center mt-8 mb-5">
      <span class="inline-flex rounded-md shadow-sm ">
        <button
          on:click={() => {
            if (last) loadMore(last)
          }}
          type="button"
          class="inline-flex items-center px-4 py-2 text-base font-medium leading-6 text-gray-700 bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50">
          Load More
        </button>
      </span>
    </div>
  {/if}
  <div slot="loading" class="flex justify-center mt-8 mb-5"><span /></div>
</Collection>
