<script>
  export let name, num

  import { createEventDispatcher } from 'svelte'
  const dispatch = createEventDispatcher()

  const svg = {
    question:
      'M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2',
    picture:
      'M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z',
    song:
      'M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3',
  }
</script>

<div
  class="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
  <label
    for="last_name"
    class="block text-sm font-medium leading-5 text-gray-700 capitalize sm:mt-px sm:pt-2">
    {num > 1 ? `${name}s` : name}
  </label>
  <div class="mt-2 sm:mt-0 sm:col-span-2">
    <div
      on:click={() => dispatch('select')}
      class="flex justify-center max-w-lg px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
      <div class="text-center">
        <svg
          class="w-12 h-12 mx-auto text-gray-400"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24">
          <path d={svg[name]} stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <p class="mt-1 text-sm text-gray-600">
          <button
            type="button"
            class="font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:outline-none focus:underline">
            Select
          </button>
          {num > 1 ? `${num} ${name}s` : `a ${name}`}
        </p>
      </div>
    </div>
  </div>
</div>
