<script>
  export let auth

  import Logo from './components/Logo.svelte'

  let email, password, error

  let loggingIn = false

  const handleError = (e) => {
    loggingIn = false
    switch (e.code) {
      case 'auth/invalid-email':
        error = 'Invalid email address.'
        break
      case 'auth/user-disabled':
        error = 'Account has been disabled.'
        break
      case 'auth/user-not-found':
        error = 'Account does not exist.'
        break
      case 'auth/wrong-password':
        error = 'Incorrect password.'
        break
      default:
        error = e.message
    }
  }
</script>

<div class="min-h-screen flex justify-center py-12 px-4 sm:px-6 lg:px-8 bg-cool-gray-900">
  <div class="max-w-md w-full">
    <div class="px-4 w-full">
      <h1 class="mt-2 mb-8 ">
        <Logo />
      </h1>
    </div>
    <form class="mt-8">
      <div>
        <label for="email" class="block text-sm leading-5 font-medium text-cool-gray-50">
          Email
        </label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <input
            bind:value={email}
            id="email"
            type="email"
            class="form-input block w-full sm:text-sm sm:leading-5"
            placeholder="you@example.com"
            aria-describedby="email-description" />
        </div>

      </div>
      <div class="mt-4">
        <label for="password" class="block text-sm leading-5 font-medium text-cool-gray-50">
          Password
        </label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <input
            bind:value={password}
            id="password"
            type="password"
            class="form-input block w-full sm:text-sm sm:leading-5"
            placeholder="Password"
            aria-describedby="password-description" />
        </div>

      </div>

      <div class="mt-2">
        {#if error}
          <p class="text-sm italic text-center text-red-500">{error}</p>
        {/if}
      </div>
      <div class="pt-4">
        <button
          on:click|preventDefault={async () => {
            loggingIn = true
            try {
              auth.signInWithEmailAndPassword(email, password).catch(handleError)
            } catch {
              handleError({ message: 'Please check your login details.', code: 'auth/default' })
            }
          }}
          class="group relative w-full flex justify-center py-2 px-4 border border-transparent
          text-sm leading-5 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-500
          focus:outline-none focus:border-purple-700 focus:shadow-outline-purple
          active:bg-purple-700 transition duration-150 ease-in-out">
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <svg
              class="h-5 w-5 text-purple-500 group-hover:text-purple-400 transition ease-in-out
              duration-150"
              fill="currentColor"
              viewBox="0 0 20 20">
              <path
                fill-rule="evenodd"
                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0
                012-2zm8-2v2H7V7a3 3 0 016 0z"
                clip-rule="evenodd" />
            </svg>
          </span>
          {#if loggingIn}...{:else}Sign in{/if}
        </button>

      </div>
    </form>
  </div>
</div>
