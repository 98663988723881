<script>
import Picker2 from '../../components/forms/Picker2.svelte'
import DatePicker from '../../components/forms/DatePicker.svelte'
import TimePicker from '../../components/forms/TimePicker.svelte'

import { parse } from 'date-fns'

import { createEventDispatcher, getContext } from 'svelte'

export let user

let venue, quiz, date, time

const dispatch = createEventDispatcher()
const db = getContext('firebase').getFirebase().firestore()
const collection = db.collection(`org/${user.org}/events`)

let id = makeId()

let loading = false

const index = {}
function getIndex(type) {
  if (!index[type]) index[type] = 1
  return index[type]++
}

async function save() {
  loading = true
  const eventId = await id

  const event = {
    venue: {
      id: venue.id,
      name: venue.name,
    },
    quiz: {
      id: quiz.id,
      name: quiz.name,
    },
    date: parse(`${date} ${time}`, 'yyyy-MM-dd HH:mm', new Date()),
    sections: quiz.sections.map((section) => {
      return {
        name: section.name,
        type: section.type,
        of: section.of,
        locked: true,
        feedback: false,
        rounds: section.rounds
          .filter((round) => round.answerable)
          .filter((round) => {
            if (!round.optional) return true
            if (round.value) return true
            if (round.parts.length) return true
            return false
          })
          .map((round) => {
            return {
              type: round.type,
              of: round.of,
              prompt: round.prompt,
              name: round.named ? round.name : '',
              value: round.value ? round.value : '',
              parts: round.parts.map((part) => {
                return {
                  id: part.id,
                  index: getIndex(section.of),
                  prompt:
                    round.prompt || round.type == 'pictures' ? part.prompt : '',
                  url: round.type == 'pictures' ? part.url : '',
                }
              }),
            }
          }),
      }
    }),
  }

  const collections = {
    questions: db.collection(`org/${user.org}/questions`),
    pictures: db.collection(`org/${user.org}/pictures`),
    music: db.collection(`org/${user.org}/music`),
  }

  const batch = db.batch()

  const eventRef = await collection.doc(eventId)

  batch.set(eventRef, event)

  await Promise.all(
    event.sections.map(async (section, sindex) => {
      await Promise.all(
        section.rounds.map(async (round, rindex) => {
          await Promise.all(
            round.parts.map(async (part, pindex) => {
              const docRef = collections[round.type].doc(part.id)
              const docData = await docRef.get().then((doc) => doc.data())
              docData.events.push({
                id: eventId,
                section: sindex,
                round: rindex,
                part: pindex,
              })
              await batch.set(docRef, docData)
            }),
          )
        }),
      )
    }),
  )

  await batch.commit()
  dispatch('goto', {
    page: '/events',
  })
}

function cancel() {
  dispatch('goto', {
    page: '/events',
  })
}

async function makeId() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let result = ''
  for (let i = 0; i < 4; i++) {
    result += characters.charAt(Math.floor(Math.random() * 26))
  }

  return await collection
    .doc(result)
    .get()
    .then((doc) => {
      if (doc.exists) {
        return makeId()
      } else {
        return result
      }
    })
}
</script>

<div class="mb-16 lg:flex lg:items-center lg:justify-between">
  <div class="flex-1 min-w-0">
    <h2
      class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
      New Event
    </h2>
  </div>
</div>

{#if !loading}
  <form>
    <div>
      <div class="mt-6 sm:mt-5">
        <div
          class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
          <label
            for="username"
            class="block text-sm font-medium leading-5 text-gray-700 capitalize sm:mt-px sm:pt-2">
            Code
          </label>
          <div class=" sm:col-span-2 sm:mt-px sm:pt-2">
            <div class="max-w-lg sm:max-w-xs">
              {#await id}
                <span class="text-sm italic leading-5 text-gray-500">...</span>
              {:then code}
                <span class="text-lg font-medium leading-6 text-gray-900">
                  {code}
                </span>
              {/await}
              <span
                on:click={() => (id = makeId())}
                class="ml-4 text-sm italic leading-5 text-gray-500 cursor-pointer hover:underline">Shuffle</span>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-6 sm:mt-5">
        <Picker2
          bind:value={venue}
          user={user}
          label={'Venue'}
          collection={'venues'} />
      </div>
      <div class="mt-6 sm:mt-5">
        <Picker2
          bind:value={quiz}
          user={user}
          label={'Quiz'}
          collection={'quizzes'} />
      </div>
      <div class="mt-6 sm:mt-5">
        <DatePicker bind:value={date} />
      </div>
      <div class="mt-6 sm:mt-5">
        <TimePicker bind:value={time} />
      </div>
    </div>

    {#if collection}
      <div class="pt-5 mt-8 border-t border-gray-200">
        <div class="flex justify-end">
          <span class="inline-flex rounded-md shadow-sm">
            <button
              on:click|once|preventDefault={cancel}
              type="button"
              class="px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800">
              Cancel
            </button>
          </span>
          <span class="inline-flex ml-3 rounded-md shadow-sm">
            <button
              on:click|once|preventDefault={save}
              class="inline-flex justify-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-purple-600 border border-transparent rounded-md hover:bg-purple-500 focus:outline-none focus:border-purple-700 focus:shadow-outline-purple active:bg-purple-700">
              Save
            </button>
          </span>
        </div>
      </div>
    {/if}
  </form>
{:else}
  <div class="flex justify-center w-full mt-8 mb-5"><span /></div>
{/if}
