<script>
import { newQuiz, context } from '../../stores.js'
import TextField from '../../components/forms/TextField.svelte'
import Picker from '../../components/forms/Picker.svelte'
import Select from '../../components/forms/Select.svelte'
import Sorter from '../../components/forms/Sorter.svelte'
import { createEventDispatcher, getContext } from 'svelte'
import { templates } from './_templates.js'

export let user

const dispatch = createEventDispatcher()
const db = getContext('firebase').getFirebase().firestore()
const collection = db.collection(`org/${user.org}/quizzes`)

let template = $context && $context.template ? $context.template : 0

if ($newQuiz == null) {
  template = 0
  $newQuiz = {
    name: '',
    sections: templates[template].sections,
  }
}

$: {
  if (template) {
    $newQuiz = {
      name: $newQuiz && $newQuiz.name ? $newQuiz.name : '',
      sections: templates[template].sections,
    }
  }
}

function select(type, num, section, round) {
  context.set({
    template,
    from: 'quiz',
    num,
    section,
    round,
  })
  dispatch('goto', {
    page: `/${type}`,
  })
}

let loading = false

async function save() {
  loading = true
  const quiz = $newQuiz
  newQuiz.set(null)
  quiz.date = new Date(Date.now())
  quiz.author = user.name
  const collections = {
    questions: db.collection(`org/${user.org}/questions`),
    pictures: db.collection(`org/${user.org}/pictures`),
    music: db.collection(`org/${user.org}/music`),
  }
  const quizRef = await collection.doc()
  const batch = db.batch()
  await batch.set(quizRef, quiz)
  const preBatch = {}
  quiz.sections.forEach((section, sindex) => {
    section.rounds.forEach((round, rindex) => {
      round.parts.forEach((part, pindex) => {
        if (!preBatch[part.id]) {
          preBatch[part.id] = {
            type: round.type,
            quizzes: [],
          }
        }
        preBatch[part.id].quizzes.push({
          id: quizRef.id,
          section: sindex,
          round: rindex,
          part: pindex,
        })
      })
    })
  })
  await Promise.all(
    Object.keys(preBatch).map(async (id) => {
      const docRef = collections[preBatch[id].type].doc(id)
      const docData = await docRef.get().then((doc) => doc.data())
      docData.quizzes = [...docData.quizzes, ...preBatch[id].quizzes]
      batch.set(docRef, docData)
    }),
  )
  await batch.commit()
  dispatch('goto', {
    page: '/quizzes',
  })
}

function cancel() {
  dispatch('goto', {
    page: '/quizzes',
  })
}
</script>

<div class="mb-16 lg:flex lg:items-center lg:justify-between">
  <div class="flex-1 min-w-0">
    <h2
      class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:leading-9 sm:truncate">
      New Quiz
    </h2>
  </div>
</div>
{#if !loading}
  <form>
    <div>
      <div>
        <div class="mt-6 sm:mt-5">
          <TextField bind:value={$newQuiz.name} label="Quiz Name" />
          <Select
            label="Template"
            bind:value={template}
            options={templates.map((t) => t.name)} />
        </div>
      </div>
      {#each templates[template].sections as section, sectionIndex}
        <div class="pt-8 mt-8 border-t border-gray-200 sm:mt-5 sm:pt-10">
          <div>
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              {section.name}
            </h3>
            <p class="max-w-2xl mt-1 text-sm leading-5 text-gray-500">
              {section.description}
            </p>
          </div>
          {#each section.rounds as round, roundIndex}
            <div class="mt-6 sm:mt-5">
              {#if round.named}
                <TextField
                  bind:value={$newQuiz.sections[sectionIndex].rounds[roundIndex].name}
                  label="Round name" />
              {/if}
              {#if round.type == 'text'}
                <TextField
                  bind:value={$newQuiz.sections[sectionIndex].rounds[roundIndex].value}
                  label={round.of.replace('_', ' ')}
                  placeholder={round.optional ? 'Leave blank if none' : ''} />
              {:else if $newQuiz.sections[sectionIndex].rounds[roundIndex].parts.length}
                <Sorter
                  bind:sortedList={$newQuiz.sections[sectionIndex].rounds[roundIndex].parts}
                  name={round.of}
                  num={round.num}
                  list={$newQuiz.sections[sectionIndex].rounds[roundIndex].parts} />
              {:else}
                <Picker
                  on:select={() => select(round.type, round.num, sectionIndex, roundIndex)}
                  name={round.of}
                  num={round.num} />
              {/if}
            </div>
          {/each}
        </div>
      {/each}
    </div>
    <div class="pt-5 mt-8 border-t border-gray-200">
      <div class="flex justify-end">
        <span class="inline-flex rounded-md shadow-sm">
          <button
            on:click|preventDefault={cancel}
            type="button"
            class="px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800">
            Cancel
          </button>
        </span>
        <span class="inline-flex ml-3 rounded-md shadow-sm">
          <button
            on:click|preventDefault={save}
            type="submit"
            class="inline-flex justify-center px-4 py-2 text-sm font-medium leading-5 text-white transition duration-150 ease-in-out bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700">
            Save
          </button>
        </span>
      </div>
    </div>
  </form>
{/if}
